<script setup lang="ts">
    import type { PriceProps } from '~/components/atm/Price.vue';

    interface ProductPriceProps extends PriceProps {
        productPriceLabel?: string | null;
        showBorder?: boolean;
    }

    defineProps<ProductPriceProps>();

    const { isInternational } = useLocales();
</script>
<template>
    <div :class="['flex flex-col gap-2 font-mono', { 'border-t border-solid border-woom-grey-mid pt-6': showBorder }]">
        <atm-product-price-label :product-price-label="productPriceLabel" />
        <div v-if="isInternational">
            <a
                :href="$t('price.plusCustomFeesLink')"
                target="_blank">
                {{ $t('price.plusCustomFees') }}
            </a>
        </div>
        <atm-price
            :price="price"
            :compare-at-price="compareAtPrice"
            :stacked="stacked"
            :alignment="alignment"
            :size="size" />
    </div>
</template>
